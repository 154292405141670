<template>
  <div class="cost">
    <Header back="返回" title="研发项目" index="首页" titleOne="科创模块" titleTwo="研发项目" beforeTitle="研发立项决议" />
    <div class="content">
      <h1>研发立项决议</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="议题 " prop="topic_discussion">
          <el-input v-model="form.topic_discussion" placeholder="请输入议题"></el-input>
        </el-form-item>
        <el-form-item label="决议时间" prop="decision_time">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            v-model="form.decision_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="决议地点">
          <el-input v-model="form.place" placeholder="请输入决议地点"></el-input>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="研发人数（人）" style="width: 100%">
          <el-input v-model="form.participants" placeholder="请输入研发人数（人）"></el-input>
        </el-form-item>
        <el-form-item label="决议内容" style="width: 100%">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="form.resolution"></el-input>
        </el-form-item>
        <el-form-item label="项目名称 ">
          <el-input v-model="form.rd_name" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="项目开始时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.start_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="项目结束时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.end_time"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="研发部总经理">
          <el-input v-model="form.charge_person" placeholder="请输入研发部总经理"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit" :disabled="isDisable">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,
      isDisable: false,
      labelPosition: "top",
      form: {
        topic_discussion: "",
        decision_time: "",
        place: "",
        participants: "",
        resolution: "",
        rd_name: "",
        start_time: "",
        end_time: "",
        charge_person: "",
        rd_id: "",
      },
      formRule: {
        topic_discussion: [
          {
            required: true,
            message: "请输入议题",
            trigger: "blur",
          },
        ],
        decision_time: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/rd/view_decision", {
          params: {
            rd_id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        this.form.rd_id = this.meber_id;
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/rd/store_decision", this.form)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.$router.push("/rd/index");
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("");
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
